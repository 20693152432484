import React, { useRef } from 'react';
import { useMachine } from '@xstate/react';
import { isEqual } from 'lodash';
import { Button, HStack, ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';
import { infiniteDataTableMachine, InfiniteDataTable } from '@ucc/react/ui';
import { useModal } from '~/hooks/useModal';
import { useCalculationAgreements } from '~/pages/projects/hooks/useCalculationAgreements';
import { ProjectIds } from '~/pages/projects/types';
import { Modals } from '~/pages/projects/ui/Finance/Finance';
import { ListGeneralAgreementPostionDocument, Rahmenvertragsposition, SortOrder } from '~/gql/ucpw/graphql';
import { SearchFilter } from '~/components/SearchFilter';
import { useSearchFilter } from '~/hooks';
import { client } from '~/apollo'
import { FinanceContext } from './financeMachine';

type Props = ProjectIds & {
    id: string;
    generalAgreementId: number;
    generalAgreementPositionIds?: number[];
    limit: number;
    context?: FinanceContext;
    send?: (args: any) => void
}

export function SelectGeneralAggrementsModalContent({
    limit = 30,
    ...props
}: Props) {
    const { filter } = useSearchFilter()
    const { infiniteDataTableProps, onReset, activeModal } = useSelectGeneralAggrement({
        filter,
        limit,
        ...props
    })

    return (
        <ModalContent rounded="none" maxWidth="container.xl">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                p={5}
            >
                {activeModal === "SelectStandardGeneralAggrements" ? 'Standard-Position auswählen' : 'RV-Position auswählen'}
                <HStack>
                    <SearchFilter
                        defaultFilterOption='beschreibungPrefix'
                        filterOptions={[
                            { label: 'Beschreibung', value: 'beschreibungPrefix' },
                            { label: 'Nr', value: 'nrPrefix' },
                        ]} />
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        Abbrechen
                    </Button>
                </HStack>
            </ModalHeader>
            <ModalBody p={0}>
                <InfiniteDataTable {...infiniteDataTableProps} />
            </ModalBody>
        </ModalContent>
    );
};

function useSelectGeneralAggrement({
    context,
    generalAgreementId,
    projectId,
    subprojectId,
    send: sendToFinanceMachine,
    filter,
    limit,
}: Props & { filter: Record<string, string | number> }) {
    const { onClose, dispatch, ...modal } = useModal();
    const props = modal?.state?.modals?.props || {};

    const { cache = {} } = context || {}
    const { byAgreement = {} } = context?.calculations || {}
    const generalAgreementPositionIds = cache?.generalAgreementPositionIds || byAgreement?.[generalAgreementId]?.generalAgreementPositionIds || []
    const [orderBy, setOrderBy] = React.useState([])
    const ref = useRef({ filter, generalAgreementId, orderBy })


    const fetchData = React.useCallback(async ({ currentPage = 0, limit = 30 }: any, { variables: eventVariables = {} }: any) => {
        const context = { clientName: 'ucpw' }
        const variables = {
            limit,
            offset: currentPage * limit,
            filter: { rahmenvertragId: generalAgreementId, ...filter },
            orderBy: [{ nr: SortOrder.Asc }],
            ...(orderBy.length && { orderBy }),
            ...eventVariables

        }

        const response = await client.query({
            query: ListGeneralAgreementPostionDocument,
            context,
            variables,
            fetchPolicy: 'network-only',
        })
        return Promise.resolve({ variables, ...response })
    }, [generalAgreementId, orderBy, filter])

    const [state, send] = useMachine(infiniteDataTableMachine, {
        services: { fetchData }
    });

    const next = React.useCallback(() => {
        send({
            type: 'FETCH_MORE',
            variables: {
                filter: { rahmenvertragId: generalAgreementId, ...filter },
                ...(orderBy.length && { orderBy })
            }
        })
    }, [orderBy, generalAgreementId, filter])

    React.useEffect(() => {
        if (!isEqual(ref.current, { filter, generalAgreementId, orderBy })) {
            send({
                type: 'FETCH_INITIAL',
                variables: {
                    filter: { rahmenvertragId: generalAgreementId, ...filter },
                    ...(orderBy.length && { orderBy })
                }
            })
            ref.current = { filter, generalAgreementId, orderBy }
        }
    }, [orderBy, filter, ref.current])

    const {
        columns,
        hiddenColumns,
    } = useCalculationAgreements({
        projectId,
        subprojectId,
        generalAgreementPositionIds: generalAgreementPositionIds,
    });

    const onSortChange = (sort: any) => setOrderBy((prev: any) => sort?.length ? sort?.map((item: any) => ({ [item?.id]: item?.desc ? 'desc' : 'asc', })) : [...prev])

    const selectAgreement = (row: any) => {
        const original = row?.original as Rahmenvertragsposition;
        const position = {
            rahmenvertragspositionId: original?.id,
            preisProEinheit: original?.preisProEinheit,
            rahmenvertragsposition: {
                leistung: original?.leistung,
                beschreibung: original?.beschreibung,
            },
        };
        sendToFinanceMachine?.({ type: 'SET_CACHE', cache: ({ cache = {} }: any = {}) => ({ ...cache, row: position }) })
        // dispatch?.({ type: 'setAgreement', data: { row } });
        dispatch?.({
            type: 'setModal',
            data: {
                modal: Modals.EditGeneralAggrementPosition,
                props
            }
        });
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    const data = React.useMemo(() => state.context.data, [state.context.data])

    return {
        onReset,
        infiniteDataTableProps: {
            next,
            totalCount: state.context.totalCount,
            hasMore: state.context.pageCount === 1 ? false : !(state.context.currentPage === state.context.pageCount),
            onClick: selectAgreement,
            pageSize: limit,
            pageCount: state.context.pageCount,
            currentPage: state.context.currentPage,
            columns: columns,
            hiddenColumns: hiddenColumns,
            data: data,
            onSortChange: onSortChange,
            loading: !state.matches('wait')
        },
        activeModal: modal?.state?.modals?.activeModal
    }
}
